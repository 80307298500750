input {
  font-size: 16px !important;
}

.mailcheck {
  color:rgb(235, 94, 0);
  margin-left:10px;
  margin-top:5px;
  margin-bottom:10px;
  font-size:12px;
}

.mailcheck-button {
  font-weight: bold;
}

.purchasePage {
  display: grid;
  grid-column-start: 2;
  grid-column-end: 10;
  grid-auto-rows: auto;
}

.purchasePage .licenseInfoPanel {
  background: #463c62;
  color: white;
}

.purchasePage .blackFriday {
  grid-row-start: 0;
  grid-row-end: 1;
  grid-column-start: 2;
  grid-column-end: 10;
  background: #000;
  color: white;
}
.purchasePage .firstContentBlock {
  cursor: pointer;
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
 background: var(--lightBackground) !important;
  color: var(--contrastColorLightBackground);


  padding: 5px;
  margin: 5px;
  margin-top: 10px;
}
.purchasePage .expandableTitle {
  font-weight: bold;
}



.purchasePage .secondContentBlock {
  cursor: pointer;

  grid-column-start: 5;
  grid-column-end: 8;
  background: var(--lightBackground) !important;
  color: var(--contrastColorLightBackground);

  grid-row-start: 1;
  grid-row-end: 2;
  padding: 5px;
  margin: 5px;
  margin-top: 10px;
}

.purchasePage .setappBox {
  cursor: pointer;

  grid-column-start: 8;
  grid-column-end: 10;
  background: var(--lightBackground) !important;
  color: var(--contrastColorLightBackground);

  grid-row-start: 1;
  grid-row-end: 2;
  padding: 5px;
  margin: 5px;
  margin-top: 10px;
}


.purchasePage .thirdContentBlock {
  grid-column-start: 2;
  grid-column-end: 10;
   background: rgb(54, 43, 74) !important;
   color: #fff !important;

  grid-row-start: 2;
  grid-row-end: 3;
  padding: 5px;
  margin: 5px;
  margin-top: 10px;
}

@media all and (max-width: 1100px) {
  .purchasePage .setappBox {
    grid-column-start: 9;
    grid-column-end: 10;
  }

  .purchasePage .secondContentBlock {
  
    grid-column-start: 5;
    grid-column-end: 9;


  }

  .shorterSetappDescription {
    display:none;
  }
}

