.subHeader {
  grid-column-start: 2;
  grid-column-end: 10;
  display: flex; /* Flex layout for horizontal scrolling */
  overflow-x: auto; /* Enable horizontal scrolling */
  gap: 20px; /* Space between cards */
  background: var(--veryLightBackground);
  padding-left: 10%;
  padding-right: 10%;
  grid-row-start: 1;
  width: 100%; /* Ensure the container spans the full width */
  box-sizing: border-box; /* Include padding in width calculation */
}

.subHeader > * {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 20px);
  max-width: calc(100% - 20px);
}

@media all and (max-width: 1100px) {
  .subHeader {
    padding-left: 2%;
    padding-right: 2%;
  }

  .subHeader > * {
    flex: 0 0 calc(100% - 20px); /* Show 2 cards per row on smaller screens */
    max-width: calc(100% - 20px);
  }
}
