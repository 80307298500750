.blogPost img {
  max-width: 90%;
  width: auto;
  height: auto;
}

.blogPost {
  color: #393939;
}
@media (prefers-color-scheme: dark) {
  .blogPost {
    color: #fff;
  }
}

.blogPost li {
  padding: 7px;
}

.blogPost {
  line-height: 150%;
}

.blogPost pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

img.markdownimage {
  filter: grayscale(0.2); /* Webkit Nightlies & Chrome Canary */
  transition: all 0.5s ease-in-out;
}

img.markdownimage:hover {
  filter: grayscale(1);
  transform: scale(1);
}