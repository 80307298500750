/* Wrapper ensures visibility of only 4 cards at a time */
.blog-post-cards-wrapper {
    overflow-x: hidden;
    width: 100%;
  }
  
  /* Horizontal scrollable container */
  .blog-post-cards-container {
    display: flex;
    gap: 20px; /* Space between cards */
    overflow-x: auto;
    width: 100%;
 
    padding: 10px;
  }
  
  /* Card takes equal space (1/4 of container width) */
  .blog-post-cards-container > * {
    flex: 1 0 calc(25% - 20px); /* Each card occupies 25% minus gap */
    max-width: calc(25% - 20px); /* Prevent cards from shrinking */
  }
  
  .blog-post-cards-container::-webkit-scrollbar {
    height: 6px;
  }
  
  .blog-post-cards-container::-webkit-scrollbar-thumb {
    background: #7b2596;
    border-radius: 10px;
  }
  
  .blog-post-cards-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  