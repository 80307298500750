img.thumbnailbox {
  filter: grayscale(0.2); /* Webkit Nightlies & Chrome Canary */
  transition: all 0.5s ease-in-out;
}

img.thumbnailbox:hover {
  filter: grayscale(1);
  transform: scale(1.1);
}

.thumbnailBox {
  display: flex;
  align-items: flex-end;
}
