header {
    grid-column-start:2; 
    grid-column-end:10; 
    grid-row-start:1; 
    grid-row-end:2;  
    display:flex;
    align-items: center;
    justify-content: center;   
    color: var(--darkText) !important;
    background: var(--veryLightBackground) !important;
}

.appmenu > div > ul{
    background: var(--contrastColorDarkBackground) !important;
}

