.privacyPage .firstContentBlock {
    grid-column-start: 2;
    grid-column-end: 10;
   background: var(--lightBackground) !important;
  
    grid-row-start: 2;
    grid-row-end: 3;
    color: var(--contrastColorLightBackground);

    padding: 5px;
    margin: 5px;
    margin-top: 10px;
  }
  