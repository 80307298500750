
.blogPage .firstContentBlock {
  grid-column-start: 2;
  grid-column-end: 10;
 background: var(--lightBackground) !important;

  color: var(--contrastColorLightBackground);

  padding: 5px;
  margin: 5px;
  margin-top: 10px;
}


