.downloadButtonArea {
  display: grid;
  align-items: center;
  grid-gap: 20px;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
}

.downloadButtonArea a {
  text-decoration: none;
  color:var(--contrastColorLightBackground) !important;
}

.downloadsPage .blackFriday {
  grid-row-start: 0;
  grid-row-end: 1;
  grid-column-start: 2;
  grid-column-end: 10;
  background: #000;
  color: white;
}

.downloadsPage .downloadBlock {
  grid-template-columns: 20% 50% 30%;
  grid-gap: 10px;
  grid-auto-rows: auto;
  align-items: center;
  justify-content: center;
  display: grid;
  color: var(--contrastColorLightBackground);
  grid-column-start: 1;
  grid-column-end: 10;
}

.downloadsPage .downloadBlock img {
  margin: 0 auto;
}

.downloadsPage .whiteBlock {
  color: var(--contrastColorDarkBackground);
}

.downloadsPage .firstContentBlock {
  grid-column-start: 2;
  grid-column-end: 10;
  background: var(--lightBackground) !important;

  grid-row-start: 2;
  grid-row-end: 3;
  color: var(--contrastColorLightBackground);
  padding: 5px;
  margin: 5px;
  margin-top: 10px;
  margin-bottom: 1px;
  grid-template-columns: 50% 50%;
  grid-auto-rows: auto;
}

.downloadsPage .secondContentBlock {
  grid-column-start: 2;
  grid-column-end: 10;
  background: var(--lightBackground) !important;

  grid-row-start: 3;
  grid-row-end: 4;
  padding: 5px;
  margin: 5px;
  margin-top: 0px;
}

.downloadsPage .thirdContentBlock {
  grid-column-start: 2;
  grid-column-end: 10;
  background: var(--lightBackground) !important;

  grid-row-start: 4;
  grid-row-end: 5;
  color: var(--contrastColorLightBackground) !important;
  padding: 5px;
  margin: 5px;

  grid-template-columns: 50% 50%;
  grid-auto-rows: auto;
}

.downloadsPage .fourthContentBlock {
  grid-column-start: 2;
  grid-column-end: 10;
  background: var(--lightBackground) !important;
  grid-row-start: 5;
  grid-row-end: 6;
  padding: 5px;
  margin: 5px;
  margin-bottom: 40px;
}
