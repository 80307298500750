.keyboardCleanToolPage .secondContentBlock {
  grid-column-start: 2;
  grid-column-end: 10;
   background: var(--darkBackground) !important;

  grid-row-start: 3;
  grid-row-end: 4;
  padding: 5px;
  margin: 5px;
  margin-bottom: 40px;
}
