.bettersnaptoolPage .descriptionHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bettersnaptoolPage .firstContentBlock {
  grid-column-start: 2;
  grid-column-end: 10;
  background: var(--lightBackground);
  color: var(--contrastColorLightBackground);

  grid-row-start: 2;
  grid-row-end: 3;
  padding: 5px;
  margin: 5px;
  margin-top: 10px;
}

.betterSnapToolThumbnails {
  margin: 10px;
  width: 200px;
  display: inline-block;
}

.betterSnapToolAppScreenshots {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.bettersnaptoolPage .secondContentBlock {
  grid-column-start: 2;
  grid-column-end: 10;
  background: var(--darkBackground) !important;

  grid-row-start: 3;
  grid-row-end: 4;
  padding: 5px;
  margin: 5px;
  margin-bottom: 40px;
}
