.cardActions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.faiCard {
  border-top: 1px solid #b8bbcb;
  margin: 5px;
  background: var(--veryLightBackground) !important;
  max-height: 220px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  color: var(--cardColor);
  
}

.faiCard:hover {
  color: var(--cardColor);
  transform: scale(0.9);
  border-top: 1px solid #7b2596;
}
