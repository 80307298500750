.startPage .logo {
  display: block;
  width: 9vw;
  position: absolute;
  margin-top: -10px;
  right: 10px;

  z-index: 1000;
}

@media all and (max-width: 1100px) {
  .startPage .logo {
    display: none;
  }
}

.triggers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  width: 700px;
  background: var(--veryLightBackground);
  border-radius: 30px;
  margin: 0 auto;
  margin-top: 20px;
  color: var(--contrastColorLightBackground) !important;

}



.startPage .blackFriday {
  grid-row-start: 0;
  grid-row-end: 1;
  grid-column-start: 2;
  grid-column-end: 10;
  background: #000;
  color: white;
}

.startPage .firstContentBlock {
  grid-column-start: 2;
  grid-column-end: 10;
  background: var(--lightBackground) !important;

  color: var(--contrastColorLightBackground) !important;

  margin: 5px;
  margin-top: 10px;
}

.startPage .secondContentBlock {
  grid-column-start: 2;
  grid-column-end: 10;
  background: var(--darkBackground) !important;
  color: var(--contrastColorDarkBackground) !important;


  padding: 5px;
  margin: 5px;
}
.startPage .thirdContentBlock {
  grid-column-start: 2;
  grid-column-end: 10;
  background: var(--lightBackground) !important;
  color: var(--contrastColorLightBackground) !important;

  padding: 5px;
  margin: 5px;
}

.startPage .lightSectionTitle {
  color: var(--contrastColorLightBackground) !important;
  margin: 0;
  margin-bottom: 5px;
}

.betterTouchoolThumbnails {
  margin: 10px;
  width: 187px;
  display: inline-block;
}
