.bttremotePage .descriptionHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.betterSnapToolThumbnails {
  margin: 10px;
  width: 200px;
  display: inline-block;
}

.betterSnapToolAppScreenshots {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.bttremotePreview {
  max-width: 900px;
  margin: 0 auto;
  padding: 50px;
}

.bttremotePage .bttremoteDetailsBlock {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-gap: 30px;
}

.bttRemotePage ThumbnailboxComponent {
  align-self: flex-end;
}

.bttremotePage .secondContentBlock {
  grid-column-start: 2;
  grid-column-end: 10;
  background: var(--darkBackground) !important;

  grid-row-start: 3;
  grid-row-end: 4;
  padding: 5px;
  margin: 5px;
}

.bttremotePage .thirdContentBlock {
  grid-column-start: 2;
  grid-column-end: 10;
  background: var(--veryLightBackground) !important;

  grid-row-start: 4;
  grid-row-end: 5;
  padding: 5px;
  margin: 5px;
  margin-bottom: 40px;
}
