/* styles */


:root {
  --darktext: #595959;
  --blue: #34495e;
  --green: #e74c3c;
  --yellow: #c9c243;
  --orange: #ffa644;
  --red: #b31926;
  --lightText: #000;
  --lightDarkText: #c2c2c2;
  --veryLightBackground: rgba(255, 255, 255, 1);
  --darkText: #332c65;
  --lightBackground: rgba(255, 255, 255, 0.98);
  --contrastColorLightBackground: black;
  --darkBackground:  rgb(54, 43, 74);
  --contrastColorDarkBackground: white;
  --gradientStart:#fc9879;
  --gradientEnd:#7d73e8;
  --darkerBackground: #573d7c;
  --darkButtonBackground:  rgb(54, 43, 74);
  --modalBackground: rgb(255,255,255,1);
  --text: rgb(64, 64, 64);
  --linkColor: #62309e;
  
}
@media (prefers-color-scheme: dark) {
  
  :root {
    --darktext: #595959;
    --blue: #34495e;
    --green: #e74c3c;
    --yellow: #c9c243;
    --orange: #ffa644;
    --red: #b31926;
    --lightText: #000;
    --lightDarkText: #c2c2c2;
    --veryLightBackground: rgba(40, 40, 40, 1);
    --darkText: #fff;
    --lightBackground: rgba(40, 40, 40, 0.98);
    --contrastColorLightBackground: white;
    --secondaryContrastColorLightBackground: rgb(230, 230, 230);
    --darkBackground:  rgb(54, 43, 74);
    --darkButtonBackground:  rgb(179, 171, 193);

    --contrastColorDarkBackground: black;
 
    --gradientStart: #9076d5;
    --gradientEnd: rgb(101 39 106);
    --cardColor:   #e5daf0;
    --darkerBackground: #a297b1;
    --modalBackground: #000;
    --linkColor: #b493dc;

  }
}

.glightbox-container .gslide-inner-content {
  background-color: rgba(40, 40, 40, 0.7);

  width: 85%;
}
html.glightbox-open {
  overflow: initial;
}

.goverlay {
  background-color: rgba(40, 40, 40, 0.7);
}

.link {
  color: '#62309e';
  cursor: 'pointer';
}

.title {
  color: var(--lightDarkText);
  position: absolute;
  right: 20px;
  margin-top: 240px;
}

body {
  background-color: var(--veryLightBackground);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  min-width: 1100px;
}

.modalBackground {
  background-color: var(--modalBackground) !important;
}

main {
  /*background: radial-gradient(circle farthest-corner at right bottom,#bf78ad 0,#7D73E8 100%);*/
  /*background: radial-gradient(circle farthest-corner at right bottom,#ff5e00 0,#7D73E8 100%);*/
  /*radial-gradient(circle farthest-corner at right center,#e8b2a0 0%,#876ac1 90%)*/

  /* background: radial-gradient(circle farthest-corner at right top,#ff734a 0%,#7D73E8 90%);*/
  background: radial-gradient(
    circle farthest-corner at right top,
    var(--gradientStart) 0%,
    var(--gradientEnd) 90%
  );

  grid-row-start: 2;
  grid-row-end: 5;
  grid-column-start: 1;
  grid-column-end: 11;
  margin: 0px;
  padding-bottom: 30px;
}

footer {
  grid-row-start: 5;
  height: 60px;
}
a {
  text-decoration: none;
  color:var(--linkColor);
}

a:visited {
  color:var(--linkColor);

}

.twoColumns1585 {
  display: grid;
  grid-template-columns: 15% 85%;
}

.twoColumns5050 {
  display: grid;
  grid-template-columns: 50% 50%;
}

.twoColumns3070 {
  display: grid;
  grid-template-columns: 30% 70%;
}

.defaultGrid {
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
}

.mainContainer {
  display: grid;
  height: 100vh;

  grid-auto-rows: 60px auto auto 60px;
  background-color: var(--veryLightBackground);
}

@media all and (max-width: 1100px) {
  .defaultGrid {
    grid-template-columns: 2% 18% 10% 10% 10% 10% 10% 10% 18% 2%;
  }

  body {
    min-width: 950px;
  }
}

.centerCardContent {
  display: grid;
}

.logo {
  display: block;
  width: 9vw;
  position: absolute;
  margin-top: -10px;
  right: 10px;
  z-index: 1000;
}
@media all and (max-width: 1100px) {
  .logo {
    display: none;
  }
}

.defaultPageLayout {
  display: grid;
  grid-column-start: 2;
  grid-column-end: 10;
  grid-auto-rows: auto;
}

.lightSectionTitle {
  color: var(--contrastColorLightBackground);
}

.firstContentBlock {
  grid-column-start: 2;
  grid-column-end: 10;
  background: var(--lightBackground) !important;

  color: var(--contrastColorLightBackground);
  padding: 5px;
  margin: 5px;
  margin-top: 10px;
}

pre[class*="language-"] { 
 font-size: 0.8em !important;
}

hr {
  color: #c2c2c26a;
  background-color: #c2c2c26a;
  height: 1px;
  border: none;
  max-width: 100%;
}

.blogPage img {
  max-width:90%;
  }

 